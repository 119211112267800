@charset "utf-8";


body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, "微軟正黑體", sans-serif;
}

/*index*/
@media (min-width: 300px) {
  .page_slider .intro_featured_word {
    font-size: 65px;
    line-height: 1.1;
  }
}

@media (max-width: 575px) and (min-width: 300px) {
  .page_slider .intro_featured_word {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
  }
}


.ds .sf-menu>li.active>a:before {
  left: 0;
  right: 0;
  width: 100%;
}



.mh-300 {
  min-height: 300px;
  display: flex;
  align-items: center;
}


a.logo img {
  /*width: 130px;*/
}

/*about*/

.widget_categories li>a {
  padding-left: 8px;
}

.widget_categories .cat-item.active a {
  color: #dd4454;
}

.widget_categories li>a:before,
.widget_categories li.active>a:before {
  width: 8px;
  height: 18px;
  border-radius: 15px;
  display: block;
  left: 0;
  position: absolute;
  top: 13px;
  color: transparent;
  content: '';
  background: #dd4454;
}

/*contatct*/
.special-column2 {
  margin-right: auto;
  max-width: 400px;
  padding-left: 62px;
}